import { useEffect } from "react"
import { gsap } from "gsap"

const changeColor = target => {
  const colors = [
    "002395",
    "158835",
    "FFB612",
    "557FCA",
    "DE3831",
    "D91825",
    "FCDC33",
  ]

  const color_index = Math.floor(Math.random() * colors.length)
  gsap.set(target, {
    fill: `#${colors[color_index]}`,
  })
}

const useHomeConfettiAnimation = confetti =>
  useEffect(() => {
    const container_width = confetti.current.getBoundingClientRect().width

    const confetti_items = gsap.utils.toArray(confetti.current.children)
    confetti_items.forEach((confetti_item, index) => {
      const item_width = confetti_item.getBoundingClientRect().width
      const item_pos_x = confetti_item.getBoundingClientRect().left
      confetti_item.addEventListener("mouseover", e => {
        changeColor(e.target)
      })
      gsap.fromTo(
        confetti_item,
        { x: -(item_pos_x + item_width) },
        {
          x: container_width - item_pos_x + item_width + item_width,
          duration: 7,
          delay: index,
          repeat: -1,
          repeatDelay: 0,
          ease: "none",
          stagger: 1,
          onRepeat: () => changeColor(confetti_item),
        }
      )
    })
  })

export default useHomeConfettiAnimation
