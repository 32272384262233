import React from 'react'
import ctl from "@netlify/classnames-template-literals"
import Container from "../../utils/container"

const IntroText = () => {
    const wrapperStyle=ctl(`
    lg:-ml-10
    sm:ml-5
    lg:mt-[113px]
    `)
    const headingStyle= ctl(`
    font-semibold 
    sm:text-4xl
    md:text-5xl 
    text-[28px] 
    leading-tight
    md:max-w-[560px]
    max-w-[400px]
    `)
    const paragraphStyle= ctl(`
    lg:max-w-[500px]
    md:max-w-[540px]
    max-w-[93%]
    mb-[57px]
    mt-[40px]
    `)

    return (
        <Container>
        <section className={wrapperStyle}>
            <h2 className={headingStyle}>What can we learn from the best examples?</h2>

            <p className={paragraphStyle}>We have revealed a chain of recurring themes that brands within the tech ecosystem — as well as those from across traditional sectors looking to build Challenger Brands ― can take a cue from.</p>

        </section>
        </Container>
    )
}

export default IntroText
