import * as React from "react"

import Layout from "../components/layout"
import IntroSection from "../components/pages/home/intro-section"
import HomeJudges from "../components/pages/home/judges"
import ACBLogo from "../components/pages/home/logo"
// import ChartSection from "../components/pages/home/chart-section"
import PrintSection from "../components/pages/home/print-section"
// import Featured from "../components/pages/home/featured-section"
import IntroText from "../components/pages/home/intro-text"

const IndexPage = ({ location }) => {
  return (
    <Layout
      title="Africa Challenger Brands by FourthCanvas"
      ignoreSiteName
      url={location.pathname}
      ignoreWaitlistForm
    >
      <ACBLogo />
      <IntroSection/>
      <IntroText/>
      <HomeJudges />
      <PrintSection/>
    </Layout>
  )
}

export default IndexPage
