import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ctl from "@netlify/classnames-template-literals"
import Container from "../../../utils/container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Description from "./description"
import Button from "../../../utils/button"

const PrintSection = () => {

  const mockupDesign = useStaticQuery(graphql`
    query {
      allContentfulMockup {
        nodes {
          acbMockup {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
      }
    }
  `)
  const mockup = getImage(mockupDesign.allContentfulMockup.nodes[0].acbMockup)

  const wrapperStyle = ctl(`
    lg:-ml-28
    md:-ml-1
    sm:ml-5
    flex
    md:flex-row
    flex-col
    items-center
    md:mt-36
    `)
  const imageWrapperStyle = ctl(`
    xl:mr-[64px]
    lg:mr-[15px]
    md:mr-[25px]
    mr-0
    `)
  const imageStyle = ctl(`
    md:h-[540px]
    sm:h-[460px]
    sm:w-[388px]
    h-[265px]
    w-[250px]
    transform 
    rotate-[-19deg]
    `)

  return (
    <Container>
      <div className={wrapperStyle}>
        <div className={imageWrapperStyle}>
          <GatsbyImage
            image={mockup}
            alt="Africa Challenger Brands Report 2021 (Cover image mockup)"
            className={imageStyle}
          />
        </div>

        <div className={`mb-16`}>
          <Description />

          <Button
            text="Order at 50USD "
            extraStyle="bg-red"
            link="https://flutterwave.com/store/fourthcanvas/mhi98lvnimcw"
          />
        </div>
      </div>
    </Container>
  )
}

PrintSection.propTypes = {}

export default PrintSection
