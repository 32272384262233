import ctl from "@netlify/classnames-template-literals"
import React from "react"
import DownloadButton from "../../../utils/download-report/button"
import { StaticImage } from "gatsby-plugin-image"

const IntroSection = () => {
  const containerStyle = ctl(`
  bg-black
  text-white
  flex
  md:flex-row
  flex-col
  items-center
  md:pt-[131px]
  pt-[80px]
  lg:pl-[240px]
  md:pl-12
  `)
 const textStyle= ctl(`
  w-full
  xl:w-[341px]
  md:w-[470px]
  md:bg-black
  bg-white
  xl:h-[211px]
  sm:px-12
  px-7
  lg:pr-8
  xl:pr-0
  lg:pl-0
  md:mb-4
  pt-[40px]
  md:pt-0
 `)
  const imageStyle=ctl(`
  lg:mr-[43px]
  max-w-[60%]
  `)
  const introParagraphStyle = ctl(`
  mb-[49px]
  md:text-white
  text-black
  max-w-[97%]
  sm:max-w-auto
  `)
  return (
      <div className={containerStyle}>
        <StaticImage 
        src="../../../../images/phone.svg" 
        alt="acb phone design" 
        placeholder="blurred"
        className={imageStyle}
        />

        <div className={textStyle}>
        <p className={introParagraphStyle}>
        In this report, you will meet the top 20 tech brands who have, over the past one year, beaten the odds and redefined what it takes to successfully engage people as they disrupt established sectors across Africa.
        </p>
       <DownloadButton text="Download report"/>
        </div>
      </div>
  )
}

IntroSection.propTypes = {}

export default IntroSection
