import React from "react"
import ctl from "@netlify/classnames-template-literals"
import TheHomeConfetti from "./confetti/index"

const ACBLogo = () => {
  const header_style = ctl(`
  bg-black 
  pt-36 
  ml 
  relative 
  overflow-hidden 
  text-white
  `)
  const style = ctl(`
  font-serif 
  md:text-7xl 
  text-4xl
  bg-white 
  text-black 
  inline-block
  md:pt-7
  pt-3

  `)

  const africa = ctl(`
  md:px-8
  px-4
  md:ml-24
  ml-12
  `)
  const challenger = ctl(`
  pl-8
  pr-20
  -my-6
  md:ml-80
  ml-40
  md:w-auto
  w-full
  `)
  const brands = ctl(`
  md:pl-24
  pl-12
  md:pr-6
  pr-4
  `)
  return (
    <header className={header_style}>
      <TheHomeConfetti />
      <h1 className={style + " " + africa}>Africa</h1>
      <br />
      <h1 className={style + " " + challenger}>Challenger</h1>
      <br />
      <h1 className={style + " " + brands}>Brands</h1>
    </header>
  )
}

export default ACBLogo
