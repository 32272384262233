import React, {useState, useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { useStaticQuery, graphql } from "gatsby"
import Judge from "../../judging-panel/judges/judge"
import { Link } from "gatsby"
import DownloadButton from "../../../utils/download-report/button"
import "swiper/swiper.scss"
import { StaticImage } from "gatsby-plugin-image"

const HomeJudges = () => {
  const [randomJudges, setRandomJudges] = useState([])

  const judges_query = useStaticQuery(graphql`
    query homejudgesQuery {
      allContentfulJudges {
        edges {
          node {
            fullname
            bio {
              bio
            }
            image {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
    }
  `)

  const allJudges = judges_query.allContentfulJudges.edges

  const showJudges = allJudges.map((judge, index) => (
    <SwiperSlide>
      <Judge
        key={index}
        name={judge.node.fullname}
        image={judge.node.image}
        home
      />
    </SwiperSlide>
  ))

  const noOfJudges = showJudges.length

  useEffect(() => {
    for (let i = 0; i < 3; i++) {
      const noOfJudges = showJudges.length

      const randomIndex = Math.floor(Math.random() * noOfJudges)

      randomJudges.push(showJudges[randomIndex])

      showJudges.splice(randomIndex, 1)
    }
  })

 
  return (
    <section className=" lg:ml-64 lg:mt-[102px] mt-[63px] md:ml-16 sm:ml-12">
      <header className="max-w-[690px]  md:mb-16 mb-10 w-full pl-8 sm:pl-0">
        <h2 className="font-semibold sm:text-4xl md:text-5xl text-[28px] md:mb-12 mb-[20px] leading-tight">
          Evaluated by experts
        </h2>
        <p className="max-w-[90%] lg:max-w-auto">
          Our panel of seasoned brand professionals contribute their informed
          judgement to the evaluation and provide useful insights into how the
          #ACB20 brands get it right.{" "}
        </p>
      </header>
      <section className="relative mb-[50px] md:mb-0 lg:ml-0 ">
        <div>
          <Swiper
            spaceBetween={0}
            slidesPerView={1.3}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {randomJudges}
          </Swiper>
        </div>
        <Link
          to="/judging-panel"
          className="group md:top-[25%] top-[72px] md:w-[102px] w-[58px] md:h-[60px] h-[40px] z-[1] bg-red absolute right-0 flex  items-center justify-center overflow-hidden"
        >
          <StaticImage
            src="../../../../images/icons/right-arrow.svg"
            alt=""
            placeholder="blurred"
            className=" transform duration-300 group-hover:translate-x-8 block md:w-[30px] w-[17px]"
          />
        </Link>
      </section>
      <DownloadButton text="Download already" extraStyle="sm:max-w-[96%] max-w-[86%] ml-8 sm:ml-0"/>
    </section>
  )
}

HomeJudges.propTypes = {}

export default HomeJudges
