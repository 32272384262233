import React from 'react'
import ctl from "@netlify/classnames-template-literals"

const Description = () => {
    const headingStyle= ctl(`
    md:text-5xl
    font-semibold
    text-[28px] 
    mt-[35px]
    leading-tight
    `)
    const printParagraphStyle = ctl(`
    mb-[40px]
    lg:w-[420px]
    md:w-[310px]
    w-[93%]
    `)

    return (
        <div>
            <h2 className={headingStyle}>Even better in print</h2>

            <p className={printParagraphStyle}>We will be releasing a few printed copies of the #AfricaChallengerBrands report by September. You can order now to reserve one of them. </p>
        </div>
    )
}

export default Description
