import React, { useRef } from "react"
import useHomeConfettiAnimation from "./animation"

const TheHomeConfetti = () => {
  const confetti = useRef()

  useHomeConfettiAnimation(confetti)
  return (
    <svg
      width="1394"
      height="408"
      viewBox="0 0 1394 408"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute top-0 left-0 md:w-full "
    >
      <g id="Group 43" ref={confetti}>
        <rect id="Rectangle 24" width="150" height="51" fill="#002395" />
        <rect
          id="Rectangle 4"
          x="975"
          y="180"
          width="130"
          height="51"
          fill="#FCDC33"
        />
        <rect
          id="Rectangle 21"
          x="504"
          y="128"
          width="115"
          height="52"
          fill="#557FCA"
        />
        <rect
          id="Rectangle 22"
          x="1325"
          y="231"
          width="69"
          height="52"
          fill="#FFB612"
        />
        <rect
          id="Rectangle 27"
          x="369"
          y="64"
          width="69"
          height="52"
          fill="#FFB612"
        />
        <rect
          id="Rectangle 25"
          x="1213"
          y="304"
          width="112"
          height="52"
          fill="#D91825"
        />
        <rect
          id="Rectangle 26"
          x="47"
          y="255"
          width="89"
          height="51"
          fill="#158835"
        />
        <rect
          id="Rectangle 28"
          x="1105"
          width="89"
          height="51"
          fill="#158835"
        />
        <rect
          id="Rectangle 30"
          x="856"
          y="64"
          width="47"
          height="52"
          fill="#DE3831"
        />
        <rect
          id="Rectangle 29"
          x="856"
          y="356"
          width="47"
          height="52"
          fill="#DE3831"
        />
      </g>
    </svg>
  )
}

export default TheHomeConfetti
